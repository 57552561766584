<div class="software-integrations-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="software-integrations-content">
                    <span class="sub-title">DATA SYNC</span>
                    <h2>Automation & Workflows</h2>
                    <p>Automate your busy work from start to finish.</p>
                    <p>We streamline the repetitive tasks and connect your system so all of your data is in sync</p>
                    <a routerLink="/contact" class="default-btn">Get A Free Consultation</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="software-integrations-list">
                    <img src="assets/img/shape/bg-shape2.png" alt="bg-shape">
                    <ul>
                        <li><img src="assets/img/software-integrations/atlassian.png" alt="atlassian"></li>
                        <li><img src="assets/img/software-integrations/hubspot.png" alt="skype"></li>
                        <li><img src="assets/img/software-integrations/gdrive.png" alt="gdrive"></li>
                        <li><img src="assets/img/software-integrations/facebook.png" alt="slack"></li>
                        <li><img src="assets/img/software-integrations/api.png" alt="jira"></li>
                        <li><img src="assets/img/software-integrations/accounting.png" alt="frame"></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>