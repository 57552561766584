<div class="features-area pb-75">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6">
                <div class="features-box">
                    <div class="icon bg1">
                        <i class="ri-code-box-line"></i>
                    </div>
                    <p>Pages Developed</p>
                    <h3><span [countUp]="1320">00</span> Pages</h3>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6">
                <div class="features-box">
                    <div class="icon bg2">
                        <i class="ri-money-dollar-box-line"></i>
                    </div>
                    <p>Client Success</p>
                    <h3>$<span [countUp]="500">00</span> Million</h3>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6">
                <div class="features-box">
                    <div class="icon bg3">
                        <i class="ri-customer-service-2-line"></i>
                    </div>
                    <p>Avg. VA Wages</p>
                    <h3>$<span [countUp]="6">00</span> /Hour</h3>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6">
                <div class="features-box">
                    <div class="icon bg4">
                        <i class="ri-24-hours-fill"></i>
                    </div>
                    <p>Avg. Time Saved</p>
                    <h3><span [countUp]="567">00</span> Hours</h3>
                </div>
            </div>
        </div>
    </div>
</div>