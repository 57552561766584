<aside class="widget-area">
    <div class="widget widget_search">
        <form class="search-form">
            <label><input type="search" class="search-field" placeholder="Search..."></label>
            <button type="submit"><i class="ri-search-2-line"></i></button>
        </form>
    </div>
    <div class="widget widget_apzel_posts_thumb">
        <h3 class="widget-title">Popular Posts</h3>
        <article class="item">
            <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg1" role="img"></span></a>
            <div class="info">
                <h4 class="title usmall"><a routerLink="/blog-details">Being The Best-Selling Smart Phone This Year</a></h4>
                <span class="date"><i class="ri-calendar-2-fill"></i> Jan 15, 2023</span>
            </div>
        </article>
        <article class="item">
            <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg2" role="img"></span></a>
            <div class="info">
                <h4 class="title usmall"><a routerLink="/blog-details">Love Songs Helped Me Through Heartbreak</a></h4>
                <span class="date"><i class="ri-calendar-2-fill"></i> Jan 14, 2023</span>
            </div>
        </article>
        <article class="item">
            <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg3" role="img"></span></a>
            <div class="info">
                <h4 class="title usmall"><a routerLink="/blog-details">Two Fashion Designers Busy With 2023 Winter Fashion</a></h4>
                <span class="date"><i class="ri-calendar-2-fill"></i> Jan 13, 2023</span>
            </div>
        </article>
        <article class="item">
            <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg4" role="img"></span></a>
            <div class="info">
                <h4 class="title usmall"><a routerLink="/blog-details">Working in the Office is a Tradition For Women</a></h4>
                <span class="date"><i class="ri-calendar-2-fill"></i> Jan 12, 2023</span>
            </div>
        </article>
    </div>
    <div class="widget widget_categories">
        <h3 class="widget-title">Categories</h3>
        <ul>
            <li><a routerLink="/blog-right-sidebar">Business <span class="post-count">(2)</span></a></li>
            <li><a routerLink="/blog-right-sidebar">Privacy <span class="post-count">(5)</span></a></li>
            <li><a routerLink="/blog-right-sidebar">Technology <span class="post-count">(6)</span></a></li>
            <li><a routerLink="/blog-right-sidebar">Tips <span class="post-count">(2)</span></a></li>
            <li><a routerLink="/blog-right-sidebar">Log in <span class="post-count">(1)</span></a></li>
            <li><a routerLink="/blog-right-sidebar">Uncategorized <span class="post-count">(1)</span></a></li>
        </ul>
    </div>
    <div class="widget widget_archive">
        <h3 class="widget-title">Archives</h3>
        <ul>
            <li><a routerLink="/blog-right-sidebar">May 2023 (1)</a></li>
            <li><a routerLink="/blog-right-sidebar">April 2023 (2)</a></li>
            <li><a routerLink="/blog-right-sidebar">June 2023 (3)</a></li>
        </ul>
    </div>
    <div class="widget widget_tag_cloud">
        <h3 class="widget-title">Tags</h3>
        <div class="tagcloud">
            <a routerLink="/blog-right-sidebar">Advertisment</a>
            <a routerLink="/blog-right-sidebar">Business</a>
            <a routerLink="/blog-right-sidebar">Life</a>
            <a routerLink="/blog-right-sidebar">Lifestyle</a>
            <a routerLink="/blog-right-sidebar">Fashion</a>
            <a routerLink="/blog-right-sidebar">Ads</a>
            <a routerLink="/blog-right-sidebar">Inspiration</a>
            <a routerLink="/blog-right-sidebar">Blog</a>
        </div>
    </div>
</aside>