<div
    class="navbar-area navbar-style-two"
    [ngClass]="{'sticky': isSticky}"
>
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" style="max-width: 20%;" routerLink="/"><img src="../../../../assets/img/logo.png" alt="logo"></a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Services</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Web Development</a></li>
                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Lead Generation</a></li>
                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Virtual Assistants</a></li>
                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Systems Automation</a></li>
                        </ul>
                    </li>
                    <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>
                    <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                </ul>
                <div class="others-option">
                    <a href="tel:18586022868" class="default-btn">Call Us: (858) 602-2868</a>
                </div>
            </div>
        </nav>
    </div>
</div>