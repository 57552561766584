<div class="banner-area">
    <div class="container-fluid">
        <div class="row align-items-center m-0">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="banner-content">
                    <span class="sub-title">FULL SERVICE MARKETING TECHNOLOGY AGENCY</span>
                    <h1>Bringing Your Visions To Life</h1>
                    <p>We are your full service technology experts that funnel customers to your business using web funnels, social ads, creative marketing, and automation.</p>
                    <a routerLink="/contact" class="default-btn">Get a Free Consultation</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 p-0">
                <div class="banner-image">
                    <img src="assets/img/banner/banner-app2.png" alt="image">
                </div>
            </div>
        </div>
    </div>
    <div class="banner-shape3"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="banner-shape6"><img src="assets/img/shape/shape16.png" alt="image"></div>
    <div class="banner-shape7"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="banner-shape8"><img src="assets/img/shape/shape11.png" alt="image"></div>
</div>