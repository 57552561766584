<div class="container">
    <div class="row justify-content-center">
        <div class="col-lg-3 col-sm-6 col-md-6">
            <div class="funfacts-box bg2">
                <div class="icon">
                    <i class="ri-price-tag-3-line"></i>
                </div>
                <p>Avg. Weekly Expenses Saved</p>
                <h3><span [countUp]="150">00</span>H</h3>
            </div>
        </div>
        <div class="col-lg-3 col-sm-6 col-md-6">
            <div class="funfacts-box">
                <div class="icon">
                    <i class="ri-money-dollar-box-line"></i>
                </div>
                <p>Loans Originated</p>
                <h3><span [countUp]="500">00</span>M</h3>
            </div>
        </div>
        <div class="col-lg-3 col-sm-6 col-md-6">
            <div class="funfacts-box bg3">
                <div class="icon">
                    <i class="ri-24-hours-fill"></i>
                </div>
                <p>Daily Hours Saved</p>
                <h3><span [countUp]="5">00</span>H</h3>
            </div>
        </div>
    </div>
</div>