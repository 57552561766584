<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="features-content">
                <span class="sub-title">BRANDING & MARKETING</span>
                <h2>Digital Adverts & Lead Funnels</h2>
                <p>The science of the silent salesman.</p>
                <p>Our in house copywriters and design team will curate the perfect content and drive traffic using digital and social advertising methods like Facebook Ads, Google PPC, SEO, and email marketing.</p>
                <div class="btn-box">
                    <a routerLink="/contact" class="default-btn">Get A Free Consultation</a>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-12">
            <div class="list-of-features">
                <div class="row justify-content-center">
                    <div class="col-lg-6 col-sm-6 col-md-6">
                        <div class="features-item with-border">
                            <div class="icon">
                                <i class="ri-google-line"></i>
                            </div>
                            <h3>PPC & SEO</h3>
                            <p>Whether if it's mobile or web based, our experienced developers will tailor the tech
                                stack to meet your needs communicating every milestone until your vision is brought to
                                life.</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6 col-md-6">
                        <div class="features-item with-border">
                            <div class="icon bg2">
                                <i class="ri-youtube-line"></i>
                            </div>
                            <h3>Content Creation</h3>
                            <p>Our in house copywriters and design team will curate the perfect content and drive
                                traffic using digital and social advertising methods like Facebook Ads, Google PPC, SEO,
                                and email marketing.</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6 col-md-6">
                        <div class="features-item with-border">
                            <div class="icon bg3">
                                <i class="ri-meta-fill"></i>
                            </div>
                            <h3>Facebook & Instagram Ads</h3>
                            <p>Whether it's a team of 3 or a team of 30, we have the capacity and experience to grow
                                your sales pipeline or grow you support team.</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6 col-md-6">
                        <div class="features-item with-border">
                            <div class="icon bg4">
                                <i class="ri-advertisement-line"></i>
                            </div>
                            <h3>Email & SMS Marketing</h3>
                            <p>If your noticing multiple repetitive tasks, we have many solutions to streamline your
                                processes. We solve the common missing data sync between your sales and operations
                                teams.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>