<div class="app-progress-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="app-progress-image text-center">
                    <img src="../../../../assets/img/web-designs.png" alt="app-img">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="app-progress-content">
                    <span class="sub-title">PRESENCE & LEGITIMACY</span>
                    <h2>Web & App Development</h2>
                    <p>First impressions give comfort and legitimacy.</p>
                    <p>Building your professionally constructed pages that will attracting more leads, increasing your sales, and scale your business.</p>
                    <a routerLink="/contact" class="default-btn">Get A Free Consultation</a>
                </div>
            </div>
        </div>
    </div>
</div>