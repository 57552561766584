<app-navbar-style-two></app-navbar-style-two>

<app-hometwo-banner></app-hometwo-banner>

<app-hometwo-features></app-hometwo-features>

<app-progress-style-one></app-progress-style-one>

<div class="features-area pb-75">
    <app-features-style-five></app-features-style-five>
</div>

<app-progress-style-two></app-progress-style-two>

<app-software-integrations></app-software-integrations>

<app-feedback-style-four></app-feedback-style-four>

<app-footer-style-one></app-footer-style-one>