<div class="app-progress-area bg-black ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="app-progress-animation-image">
                    <img src="assets/img/clockwork-va.png" alt="app-progress">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="app-progress-content text-white">
                    <span class="sub-title">TRAINED TEAM</span>
                    <h2>Virtual Assistants</h2>
                    <p>It's not just easier, it's cost effective.</p>
                    <p>Companies rely on our assistants to get more done every single day. Whether it's a sales team of 3 or operations team of 30, they're trained and ready to work.</p>
                    <a routerLink="/contact" class="default-btn">Get A Free Consultation</a>
                </div>
            </div>
        </div>
    </div>
</div>