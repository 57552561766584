<div class="feedback-area bg-F4F8FC ptb-100">
    <div class="container">
        <div class="feedback-tab-slides">
            <owl-carousel-o [options]="feedbackTabSlides">
                <ng-template carouselSlide>
                    <div class="feedback-box">
                        <p>We've been partnering with Think Loud Agency for the past three years, and their marketing expertise has played a pivotal role in our success. Their innovative strategies, attention to detail, and dedication to our brand have consistently exceeded our expectations. We highly recommend Think Loud Agency for any company seeking exceptional marketing solutions.</p>
                        <h6><span>Jeni W.</span> - Nvestor Funding</h6>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="feedback-box">
                        <p>Think Loud Agency has been an invaluable asset to our marketing efforts over the last year. Their team's commitment to understanding our unique brand and target audience has resulted in outstanding campaign results. We appreciate their responsiveness and ability to adapt to our changing needs. United Legacy is proud to work with Think Loud Agency.</p>
                        <h6><span>Jake S.</span> - United Legacy</h6>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="feedback-box">
                        <p>Loan House has had the pleasure of partnering with Think Loud Agency for the past few years, and their impact on our business has been remarkable. Their data-driven approach and creative marketing strategies have consistently driven positive results. We've seen significant growth in our online presence and customer engagement, all thanks to Think Loud Agency's expertise.</p>
                        <h6><span>Nelson H.</span> - Loan House</h6>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="feedback-box">
                        <p>We're excited about our recent partnership with Think Loud Agency. In just two months, their team has shown an impressive ability to understand our industry and quickly develop effective marketing strategies. Their dedication, professionalism, and fresh ideas have already made a positive impact on our brand. We look forward to continued success with Think Loud Agency.</p>
                        <h6><span>Don H.</span> - Rockrose Funding</h6>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>